






























































































import Vue from 'vue';
import { AuthService } from '@/lib/services';
import { Component } from 'vue-property-decorator';
import { unwrapError, headOrSelf } from '@/lib/helpers';
import Logo from '@/components/Logo.vue';
import { namespace } from 'vuex-class';
import TnfConfig from '@/config';

const sAuth = namespace('auth');

@Component({
  components: { Logo },
})
export default class Login extends Vue {
  @sAuth.Getter('isAccountant') isAccountant: boolean;

  turnstileLoaded!: (param: string) => void;
  turnstilePromise!: Promise<string>;
  email: string = '';
  password: string = '';
  loading: boolean = false;
  sso: boolean = false;

  async mounted(): Promise<void> {
    if (this.$route.query['token']) {
      this.loading = true;
      this.sso = true;
      try {
        await AuthService.authenticate(
          headOrSelf(this.$route.query['token']) || '',
        );
        this.afterLogin();
      } catch (e) {
        this.$toaster.error('Login failed', unwrapError(e));
        this.sso = false;
        this.loading = false;
      }
    }

    this.bootTurnstile();
  }

  setTurnstilePromise(): void {
    this.turnstilePromise = new Promise((resolve) => {
      this.turnstileLoaded = resolve;
    });
  }

  bootTurnstile(): void {
    this.setTurnstilePromise();
    window.turnstile.ready(() => {
      window.turnstile.render('#turnstile-captcha', {
        sitekey: TnfConfig.turnstile,
        appearance: 'interaction-only',
        callback: (token) => {
          this.turnstileLoaded(token);
        },
        'before-interactive-callback': () => {
          this.loading = false;
          // If we have to show the captcha to the user, we have to reset the promise
          this.setTurnstilePromise();
        },
      });
    });
  }

  async login(): Promise<void> {
    this.loading = true;
    // If user didn't interact with the captcha, we have to show it
    setTimeout(() => {
      this.loading = false;
      this.setTurnstilePromise();
    }, 3000);
    const captcha = await this.turnstilePromise;
    // If we had to show the captcha, lets go back to lading
    this.loading = true;
    try {
      if (await AuthService.login(this.email, this.password, captcha)) {
        this.afterLogin();
      }
    } catch (e) {
      this.$toaster.error('Login failed', unwrapError(e));
      this.loading = false;
      this.$nextTick(this.bootTurnstile);
    }
  }

  afterLogin(): void {
    if (this.$store.state.settings.subscription.satisfied) {
      if (this.isAccountant) {
        this.$router.push({ name: 'accountant' });
      } else if ('redirect' in this.$route.query) {
        const redirect = headOrSelf(this.$route.query['redirect']);
        if (redirect == null) {
          this.$router.push('/');
        } else {
          this.$router.push(redirect);
        }
      } else {
        this.$router.push('/');
      }
    } else {
      this.$router.push('/confirm-subscription');
    }
  }
}
